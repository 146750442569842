import React, { useState } from "react";
import AnimateHeight from "react-animate-height";
import { useStaticQuery, graphql } from "gatsby";

export const BButton = ({ link, ctitle }) => {
  return (
    <div className="su-button-center">
      <a href={link} className="su-button" target="_blank" rel="nofollow">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path fill="currentColor" d="M256 8c137 0 248 111 248 248S393 504 256 504 8 393 8 256 119 8 256 8zm113.9 231L234.4 103.5c-9.4-9.4-24.6-9.4-33.9 0l-17 17c-9.4 9.4-9.4 24.6 0 33.9L285.1 256 183.5 357.6c-9.4 9.4-9.4 24.6 0 33.9l17 17c9.4 9.4 24.6 9.4 33.9 0L369.9 273c9.4-9.4 9.4-24.6 0-34z"></path>
        </svg>
        <span>{ctitle || "View on Amazon"}</span>
      </a>
    </div>
  );
};

const GetChild = (achild) => {
  return achild?.props?.children ? GetChild(achild?.props?.children) : achild;
};

export const ProsNCons = ({ pros, cons, children }) => {
  const _pros = children ? GetChild(children.find((item) => item.props.mdxType === "Pros")).split("\n") : pros;
  const _cons = children ? GetChild(children.find((item) => item.props.mdxType === "Cons")).split("\n") : cons;

  return (
    <div className="su-row">
      <div className="su-column su-column-size-1-2">
        <div className="su-box-title">Pros</div>
        <div className="su-box-content su-u-clearfix su-u-trim">
          <div className="su-list">
            <ul>
              {_pros.map(
                (item, index) =>
                  !!item && (
                    <li key={index}>
                      <span>{item}</span>
                    </li>
                  )
              )}
            </ul>
          </div>
        </div>
      </div>
      <div className="su-column su-column-size-1-2">
        <div className="su-box-title">Cons</div>
        <div className="su-box-content su-u-clearfix su-u-trim">
          <div className="su-list">
            <ul>
              {_cons.map(
                (item, index) =>
                  item && (
                    <li key={index}>
                      <span>{item}</span>
                    </li>
                  )
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export const SpecTable = ({ spec, value, children }) => {
  const _spec = children ? GetChild(children.find((item) => item.props.mdxType === "Spec")).split("\n") : spec;
  const _value = children ? GetChild(children.find((item) => item.props.mdxType === "Value")).split("\n") : value;

  return (
    <table className="spec_table">
      <tbody>
        {_spec.map(
          (item, index) =>
            item && (
              <tr key={index}>
                <td>
                  <strong>{item}</strong>
                </td>
                <td>{_value[index]}</td>
              </tr>
            )
        )}
        <tr></tr>
      </tbody>
    </table>
  );
};

export const PTitle = ({ title, beforeTitle, afterTitle, link, hlevel, cName = "box_title", cEnable, disableTitle }) => {
  const Heading = `h${hlevel || "2"}`;
  const id = title
    .replace(/[^\w ]/, "")
    .split(" ")
    .join("_");

  return (
    <Heading id={id} className={cEnable !== false ? `${cName}` : ""}>
      {beforeTitle}
      {disableTitle === "true" ? null : link ? (
        <a href={link} target="_blank" rel="nofollow">
          {title}
        </a>
      ) : (
        title
      )}
      {afterTitle}
    </Heading>
  );
};

export const PImage = ({ alt, src, link, btn = true }) => {
  const name = src.split("/").filter(Boolean).pop();
  const lastDot = name.lastIndexOf(".");
  const ext = name.substring(lastDot, name.length);
  const { width, height } = useAllImages(src);
  const Anchor = link ? "a" : React.Fragment;
  const attb = link ? { href: link } : {};

  return (
    <div className="wp-block-image pimage">
      <figure className="aligncenter size-large is-resized">
        <Anchor {...attb}>
          <picture>
            {ext !== ".gif" && <source srcSet={`/img/${src}`} />}
            <img src={`/img/${src}`} alt={alt} loading="lazy" width={width} height={height} />
          </picture>
        </Anchor>
      </figure>
      {link && btn && <BButton link={link} ctitle="Check Price" />}
    </div>
  );
};

export const TImage = ({ alt, src }) => {
  const name = src.split("/").filter(Boolean).pop();
  const lastDot = name.lastIndexOf(".");
  const fileName = name.substring(0, lastDot);
  const { width, height } = useAllImages(src);

  return (
    <picture>
      <source media="(min-width:768px)" srcSet={`/img/${src}?nf_resize=fit&w=110`} />
      <source media="(min-width:100px)" srcSet={`/img/${src}?nf_resize=fit&w=220`} />
      <img src={`/img/${src}`} alt={alt} loading="lazy" width={width} height={height} />
    </picture>
  );
};

export const TableOfContents = ({ h3enabled, data }) => {
  const [height, setHeight] = useState(0);
  const [btnText, setBtnText] = useState("Show");

  const openNav = () => {
    if (height === 0) {
      setHeight("auto");
      setBtnText("Hide");
    } else {
      setHeight(0);
      setBtnText("Show");
    }
  };

  return (
    <div className="post_toc">
      <div className="post_toc_top">
        <div className="post_toc_title">Table of Contents</div>
        <button className="post_toc_btn" onClick={() => openNav()}>
          {btnText}
        </button>
      </div>
      <AnimateHeight height={height}>
        <nav>
          {data.map((item, index) => {
            if (h3enabled === false && item.heading === "3") {
              return null;
            }
            return (
              <li key={index}>
                <a href={`#${item.id}`}>{item.title}</a>
              </li>
            );
          })}
        </nav>
      </AnimateHeight>
    </div>
  );
};

export const InnerText = ({ children }) => {
  const [height, setHeight] = useState(280);
  const [btnDisplay, setBtnDisplay] = useState("block");
  const openReview = () => {
    setBtnDisplay("none");
    setHeight("auto");
  };

  return (
    <div className="box_text">
      <div className="box_inner_text">
        <AnimateHeight height={height}>{children}</AnimateHeight>
      </div>
      <div className="box_text_btn" style={{ display: btnDisplay }}>
        <button onClick={() => openReview()}>Read Full Review</button>
      </div>
    </div>
  );
};

export const BestProduct = ({ title, src, pros, link }) => {
  const { width, height } = useAllImages(src);

  return (
    <div className="best-product">
      <div className="best-icon">
        <img src="/img/best-product.png" alt="Best Product" loading="lazy" width="90" height="90" />
      </div>
      <div className="best-rating">
        <span className="star"></span>
        <span className="star"></span>
        <span className="star"></span>
        <span className="star"></span>
        <span className="star"></span>
      </div>
      <div className="best-heading">
        <p>{title}</p>
      </div>
      <div className="best-image">
        <a href={link}>
          <img alt={title} loading="lazy" src={`/img/${src}`} width={width} height={height} />
        </a>
      </div>
      <div className="best-pros">
        {pros.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </div>
      <div className="best-btn">
        <BButton link={link} />
      </div>
    </div>
  );
};

export const useAllImages = (src) => {
  const { allImageSharp } = useStaticQuery(graphql`
    query AllImages {
      allImageSharp {
        nodes {
          fluid {
            originalName
            height: presentationHeight
            width: presentationWidth
          }
        }
      }
    }
  `);
  const image = allImageSharp.nodes.filter((item) => item.fluid.originalName === src)[0];
  return (image && image.fluid) || {};
};

export const AnchorID = ({ children }) => {
  return (
    <a
      href={`#${children
        .replace(/[^\w ]/, "")
        .split(" ")
        .join("_")}`}
    >
      {children}
    </a>
  );
};

export const YTFrame = ({ id }) => {
  return <iframe className="iframe-center" width="560" height="315" src={`https://www.youtube.com/embed/${id}`} srcDoc={`<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href="https://www.youtube.com/embed/${id}?autoplay=1"><img src="https://img.youtube.com/vi/${id}/hqdefault.jpg" loading="lazy"><span>▶</span></a>`} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen title="The Dark Knight Rises: What Went Wrong? – Wisecrack Edition"></iframe>;
};
