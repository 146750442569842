import React, { useEffect, useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { FindCategory } from "../components/SimpleFunctions.js";

const LatestPosts = ({ category, id }) => {
  const allPosts = useStaticQuery(graphql`
    {
      allMdx(sort: { order: DESC, fields: [frontmatter___date] }) {
        nodes {
          id
          fields {
            slug
          }
          frontmatter {
            title
            cat
            featuredimage {
              name
              base
              childImageSharp {
                original {
                  height
                  width
                }
              }
            }
          }
        }
      }
    }
  `);

  const { nodes: posts } = allPosts.allMdx;
  const _filterdArray = posts.filter((post) => post.frontmatter.cat === category);
  const _latestPosts = [];
  const maxLength = _filterdArray.length > 6 ? 6 : _filterdArray.length - 1;
  for (let i = 0; _latestPosts.length < maxLength; i++) {
    if (_filterdArray[i].id !== id) {
      _latestPosts.push(_filterdArray[i]);
    }
  }

  return (
    !!_latestPosts.length && (
      <div className="cat-section latest-posts">
        <p className="lp-title">{category ? "Related Posts" : "Latest Posts"}</p>
        <div className="index-columns">
          {_latestPosts.map((post) => {
            const id = post.id;
            const slug = post.fields.slug;
            const { title, cat: category } = post.frontmatter;
            const { base: img } = post.frontmatter.featuredimage;
            const { width, height } = post.frontmatter.featuredimage.childImageSharp.original;
            const { categoryName, categoryLink } = FindCategory(category);

            return (
              <article className="index-column hentry" key={id}>
                <div className="index-col-image">
                  <Link to={`${slug}/`}>
                    <picture>
                      <source srcSet={`/img/${img}?nf_resize=fit&w=385&h=180`} />
                      <img src={`/img/${img}`} alt={title} loading="lazy" width={width} height={height} />
                    </picture>
                  </Link>
                </div>
                <div className="index-box-cat" rel="bookmark">
                  <Link to={`${categoryLink}/`}>{categoryName}</Link>
                </div>
                <div className="index-box-title">
                  <Link to={`${slug}/`}>{title}</Link>
                </div>
              </article>
            );
          })}
        </div>
      </div>
    )
  );
};

export default LatestPosts;
