import React from "react";
import { Link } from "gatsby";

const Writer = ({ writer }) => {
  const writers = ["Zakriya Amir", "Atif Liaqat"];
  const descriptions = ["Zakriya is right from Pakistan. He was raised with a quest for gaming craze with an organization of specialized folks. This is the place he understood that computer games can grow your creative mind to unlimited universes. Gaming is no longer for him only a method for amusement, yet enthusiasm. From the youthful age of 8, gaming was simply the main method for communicating. At whatever point he got the opportunity to play computer games, he took it with two hands and played for quite a long time. Much to his dismay that gaming would turn into his closest companion.", "I have been fond of computer technology for a long time, ever since childhood, and was always meddling with various hardware and software. With graduation in computer engineering from a recognized university like NUST was a dream come true and enabled me to pursue my dreams in swift ways. Currently, I am working for various websites as a hardware reviewer and content writer."];
  const images = ["Zakriya Amir.jpeg", "Atif Liaqat.jpeg"];
  const links = ["/author/zakriya-amir/", "/author/atif-liaqat/"];
  const index = writers.findIndex((_writer) => writer === _writer);

  return (
    <div className="writer" itemProp="author">
      <Link to={links[index]} className="writer-img-link">
        <img alt={writers[index]} src={`/img/${images[index]}`} className="writer-img" loading="lazy" width="100" height="100" />
      </Link>
      <div className="writer-text vcard" itemScope itemType="https://schema.org/Person">
        <Link to={links[index]} className="url fn writer-title-link author" itemProp="url" rel="author">
          <span itemProp="name">{writers[index]}</span>
        </Link>
        <p>{descriptions[index]}</p>
      </div>
    </div>
  );
};

export default Writer;
